<template lang="pug">
  login-create-password(
    namespace='user'
    scope='platform'
  )
</template>

<script>
export default {
  name: 'LoginPasswordConfirmation',

  mounted() {
    this.$auth.expire()
  },
}
</script>
